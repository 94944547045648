import React from "react";
import { Card } from "@mui/material";
import DrawerPrincipal from "../../component/drawer/drawer-principal";

function DashboardSuporte(){

    return (
        <>
            <DrawerPrincipal title={"Suporte"} divOpen={
                
                <div className="iframe-container">
                    <Card>
                        <div className="iframe-wrapper">
                            <iframe
                                className="responsive-iframe"
                                src="https://trendsuport3.glide.page"
                                allowFullScreen
                            />
                        </div>
                    </Card>
                </div>
            }/>
        </>
    );
}


export default DashboardSuporte;