import React from "react";
import { Fragment} from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import NewAvailability from "../view/availability/insert-availability";
import ListAvailability from "../view/availability/list-availability";
import NewCircuit from "../view/circuit/insert-circuit";
import ListCircuit from "../view/circuit/list-circuit";
import NewCustomer from "../view/customer/insert-customer";
import ListCustomer from "../view/customer/list-customer";
import Home from "../view/home/home";
import SignIn from "../view/login/login";
import NewPanel from "../view/panel/insert-panel";
import ListPanel from "../view/panel/list-panel";
import NewSale from "../view/sale/insert-sale";
import ListSale from "../view/sale/list-sale";
import NewService from "../view/service/insert-service";
import ListService from "../view/service/list-service";
import NewUser from "../view/user/insert-user";
import User from "../view/user/list-user";
import DashboardScp from "../view/Dashboard-scp/dashboard-scp";
import ListBudget from "../view/budget/list-budget";
import DashboardSuporte from "../view/dash-suport/dashboad-suporte";

const Private = ({Item}) => {

    const { signed, loading  } = useAuth();

    if(loading){
        return <div className="loading">Carregando...</div>;
    }

    return signed > 0 ? <Item /> : <SignIn />;
}

const RouteApp = () => {
    return(
        <BrowserRouter>
            <Fragment>
                <Routes>
                    <Route path="/login" element={<SignIn />} />
                    <Route exact path="*" element={<SignIn />} />

                    <Route exact path="/" element={<Private Item={Home} />}/>
                    <Route exact path="/user" element={<Private Item={User} />}/>

                    <Route exact path="/listPanel" element={<Private Item={ListPanel} />}/> 
                    <Route exact path="/panel/:id" element={<Private Item={NewPanel} />}/> 
                    <Route exact path="/panel" element={<Private Item={NewPanel} />}/> 

                    <Route exact path="/listCustomer" element={<Private Item={ListCustomer} />}/>
                    <Route exact path="/customer/:id" element={<Private Item={NewCustomer} />}/>
                    <Route exact path="/customer" element={<Private Item={NewCustomer} />}/>

                    <Route exact path="/listService" element={<Private Item={ListService} />}/>
                    <Route exact path="/service/:id" element={<Private Item={NewService} />}/>
                    <Route exact path="/service" element={<Private Item={NewService} />}/>

                    <Route exact path="/listCircuit" element={<Private Item={ListCircuit} />}/>
                    <Route exact path="/circuit/:id" element={<Private Item={NewCircuit} />}/>
                    <Route exact path="/circuit" element={<Private Item={NewCircuit} />}/>

                    <Route exact path="/listSale" element={<Private Item={ListSale} />}/>
                    <Route exact path="/sale/:id" element={<Private Item={NewSale} />}/>
                    <Route exact path="/saleReopen/:id/:isOpen" element={<Private Item={NewSale} />}/>
                    <Route path="/saleParams" element={<Private Item={NewSale} />}/>
                    <Route exact path="/sale" element={<Private Item={NewSale} />}/>

                    <Route exact path="/newUser" element={<Private Item={NewUser} />}/>
                    <Route exact path="/newUser/:id" element={<Private Item={NewUser} />}/>
                    <Route exact path="/userList" element={<Private Item={User} />}/>

                    <Route exact path="/availability" element={<Private Item={NewAvailability} />}/>
                    <Route exact path="/availability/:id" element={<Private Item={NewAvailability} />}/>

                    <Route exact path="/dashboardscp" element={<Private Item={DashboardScp} />}/>
                    <Route exact path="/dashboardSuport" element={<Private Item={DashboardSuporte} />}/>

                    {/* <Route exact path="/budget" element={<Private Item={DashboardScp} />}/> */}
                    <Route exact path="/budgetList" element={<Private Item={ListBudget} />}/>

                </Routes>
            </Fragment>
        </BrowserRouter> 
    )
}

export default RouteApp;