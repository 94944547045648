import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link, useNavigate } from 'react-router-dom';
import AutoAwesomeMosaicTwoToneIcon from '@mui/icons-material/AutoAwesomeMosaicTwoTone';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import CoPresentTwoToneIcon from '@mui/icons-material/CoPresentTwoTone';
import ManageAccountsTwoToneIcon from '@mui/icons-material/ManageAccountsTwoTone';
import ConnectedTvTwoToneIcon from '@mui/icons-material/ConnectedTvTwoTone';
import BorderAllRoundedIcon from '@mui/icons-material/BorderAllRounded';
import MonetizationOnTwoToneIcon from '@mui/icons-material/MonetizationOnTwoTone';
import logo from '../../assets/img/nova-logo-branca.png'
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone';
import AccessTimeTwoToneIcon from '@mui/icons-material/AccessTimeTwoTone';
import useAuth from '../../hooks/useAuth';
import packageJson from '../../../package.json';
import { Layout, Tag } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { Content } from 'antd/es/layout/layout';
import LaptopWindowsTwoToneIcon from '@mui/icons-material/LaptopWindowsTwoTone';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function DrawerPrincipal(props) {

var div = props.divOpen.props.children.props.children;

  const { signout } = useAuth();
  
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const usuario = JSON.parse(localStorage.getItem('user_token'));
  const permSale = usuario.roles.filter(item=>item.name == "Vendas")[0];
  const permCircuit = usuario.roles.filter(item=>item.name == "Circuito")[0];
  const permClient = usuario.roles.filter(item=>item.name == "Cliente")[0];
  const permPanel = usuario.roles.filter(item=>item.name == "Painel")[0];
  const permService = usuario.roles.filter(item=>item.name == "Serviço")[0];
  const permUser = usuario.roles.filter(item=>item.name == "Usuario")[0];
  const permDashScp = usuario.roles.filter(item=>item.name == "Dashboard SCP")[0];
  const permDash = usuario.roles.filter(item=>item.name == "Dashboard")[0];
  const permSuport = usuario.roles.filter(item=>item.name == "Suporte")[0];

  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar
            sx={{
                bgcolor: '#000000'
            }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {usuario.name}
          </Typography>

          <Layout>
              <Sider style={{background: '#000000'}}></Sider>
              <Content style={{background: '#000000'}}></Content>
              <Sider style={{background: '#000000'}}>
                <Tag color={packageJson.ambiente == 'Homologação' ? "orange" : '#000'}>
                  {packageJson.ambiente}: {packageJson.version}
                </Tag>
              </Sider>
            </Layout>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader style={{background: '#000'}}>
            <img src={logo} width={"180"} height={"40"}/>
            <IconButton style={{color: '#FFF'}} onClick={handleDrawerClose}>
                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
        </DrawerHeader>
        <Divider />
        <List>

              {
                  permDash.isinsert ? 
                <Link to={"/"} style={{textDecorationLine: "none", color: "#707070" }}>
                  <ListItem key={"/"} disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <AutoAwesomeMosaicTwoToneIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Dashboard"} />
                      </ListItemButton>
                  </ListItem>
                </Link>
                : 
                "" 
              } 

              {
                permDashScp.isinsert ? 
                <Link to={"/dashboardscp"} style={{textDecorationLine: "none", color: "#707070" }}>
                  <ListItem key={"/dashboardscp"} disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <DashboardTwoToneIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Dashboard SCP"} />
                      </ListItemButton>
                  </ListItem>
                </Link>
              : 
                "" 
              } 

              {
                permClient.islist ? 
                  <Link to={"/listCustomer"} style={{textDecorationLine: "none", color: "#707070" }}>
                    <ListItem key={"/listCustomer"} disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            <CoPresentTwoToneIcon />
                          </ListItemIcon>
                          <ListItemText primary={"Cliente"} />
                        </ListItemButton>
                    </ListItem>
                  </Link>
                  : 
                  ""
              }
              {
                permService.islist ? 
                  <Link to={"/listService"} style={{textDecorationLine: "none", color: "#707070" }}>
                    <ListItem key={"/listService"} disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            <ManageAccountsTwoToneIcon />
                          </ListItemIcon>
                          <ListItemText primary={"Serviços"} />
                        </ListItemButton>
                    </ListItem>
                  </Link>
                : 
                ""
              }
              {
              permPanel.islist ? 
                <Link to={"/listPanel"} style={{textDecorationLine: "none", color: "#707070" }}>
                  <ListItem key={"/listPanel"} disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                        <ConnectedTvTwoToneIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Painel"} />
                      </ListItemButton>
                  </ListItem>
                </Link>
                :
                ""
            }
            {
              permCircuit.islist ? 
                <Link to={"/listCircuit"} style={{textDecorationLine: "none", color: "#707070" }}>
                  <ListItem key={"/listCircuit"} disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                        <BorderAllRoundedIcon /> 
                        </ListItemIcon>
                        <ListItemText primary={"Circuito"} />
                      </ListItemButton>
                  </ListItem>
                </Link>
              : 
              ""
            }
        </List>
        <Divider />
        <List>
              {/* <Link to={"/availability"} style={{textDecorationLine: "none", color: "#707070" }}>
                <ListItem key={"Disponibilidade"} disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <EventAvailableTwoToneIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Disponibilidade"} />
                      </ListItemButton>
                </ListItem>
              </Link> */}
              <Link to={"/budgetList"} style={{textDecorationLine: "none", color: "#707070" }}>
                <ListItem key={"/budgetList"} disablePadding> 
                      <ListItemButton>
                        <ListItemIcon>
                          <AccessTimeTwoToneIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Orçamento"} />
                      </ListItemButton>
                </ListItem>
              </Link>
       {
        permSale.islist ? 
              <Link to={"/listSale"} style={{textDecorationLine: "none", color: "#707070" }}>
                <ListItem key={"/listSale"} disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <MonetizationOnTwoToneIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Venda"} />
                      </ListItemButton>
                </ListItem>
              </Link>
              : 

              " "
       }
        </List>
        <Divider />
        <List>
            {
              permUser.islist ? 
                <Link to={"/user"} style={{textDecorationLine: "none", color: "#707070" }}>
                  <ListItem key={"/user"} disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            <AccountBoxTwoToneIcon />
                          </ListItemIcon>
                          <ListItemText primary={"Usuário"} />
                        </ListItemButton>
                  </ListItem>
                </Link>
              : 
              ""
            }

            {
                permSuport.isinsert ? 
                
                <Link to="#"
                  onClick={() => window.open("https://trendsuport3.glide.page", "_blank", "noopener,noreferrer")}
                  style={{textDecorationLine: "none", color: "#707070" }}>
                  <ListItem key={"https://trendsuport3.glide.page"} disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <LaptopWindowsTwoToneIcon />
                        </ListItemIcon>
                        <ListItemText primary={"Suporte"} />
                      </ListItemButton>
                  </ListItem>
                </Link>
              : 
                "" 
              }

              <Link to={"#"} style={{textDecorationLine: "none", color: "#707070" }}>
                <ListItem key={"#"} disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <ExitToAppTwoToneIcon />
                        </ListItemIcon>
                        <ListItemText onClick={() => [signout(), navigate("/")]} primary={"Sair"} />
                      </ListItemButton>
                </ListItem>
              </Link>

        </List>
        {/* <List>
          {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List> */}
        {/* <Divider /> */}
        {/* <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List> */}
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {div}
      </Box>
    </Box>
  );
}
